import { useEffect } from "react";
import Carousel from "../components/Carousel";
import ClassicProductList from "../components/ClassicProductList";
import ThemeCard from "../components/ThemeCard";
import { Products } from "../data/Products";
import { useSelectedLocation } from "../contexts/SelectedLocationContext";
import { Theme } from "../types/Theme";
import { getCurrentYear, getSeason } from "../extensions/helpers";
import ContactUs from "../components/ContactUs";
import { HomePageCarouselImages } from "../data/CarouselImages";

const HomePage = () => {
  const {
    setCategory,
    setProductLine,
    setProduct,
    setTheme,
    setSeason,
    setYear,
  } = useSelectedLocation();
  const classicProducts = Products.filter((p) =>
    p.themes.map((t) => t.theme).includes(Theme.Classic)
  );

  useEffect(() => {
    setCategory("Home");
    setProductLine(null);
    setProductLine(null);
    setProduct(null);
    setTheme(null);
    setSeason(null);
    setYear(null);
  }, [setCategory, setProduct, setProductLine, setSeason, setTheme, setYear]);

  return (
    <>
      <Carousel images={HomePageCarouselImages} interval={7500} />
      <div className="homepage-container">
        <div className="homepage-boxes">
          <ThemeCard theme={Theme.New} />
          <ThemeCard
            theme={Theme.Season}
            season={getSeason(true)}
            year={getCurrentYear()}
          />
          <ContactUs />
        </div>
        <ClassicProductList products={classicProducts} />
      </div>
    </>
  );
};

export default HomePage;

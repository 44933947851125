export enum ProductLine {
  Polos = "Polos",
  // Swim = "Swim",
  // Quarterzips = "Quarterzips",
  // Hoodies = "Hoodies",
  Tshirts = "Tshirts",
  // Crewnecks = "Crewnecks",
  Sweaters = "Sweaters",
  // Kids = "Kids",
  // Ropehats = "Ropehats",
  // Surfhats = "Surfhats",
  // Buckethats = "Buckethats",
}

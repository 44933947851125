// src/components/Header.tsx

import React, { useState, useCallback } from "react";
import debounce from "lodash.debounce";
import { Link } from "react-router-dom";
import { useCart } from "../contexts/CartContext";
import TabDropdownList from "./TabDropdownList";
import { useHamburgerMenu } from "../contexts/HamburgerMenuContext";
import { US } from "country-flag-icons/react/3x2";

interface HeaderProps {
  onSearch: (query: string) => void;
}

const Header: React.FC<HeaderProps> = ({ onSearch }) => {
  const { isCartOpen, setIsCartOpen } = useCart();
  const { setIsHamburgerMenuOpen } = useHamburgerMenu();
  const [searchQuery, setSearchQuery] = useState("");

  // Create a debounced version of the onSearch function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((query: string) => {
      onSearch(query);
    }, 300),
    [onSearch]
  );

  const clearSearch = () => {
    setSearchQuery("");
    onSearch("");
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  return (
    <header className="header">
      {/* <!-- Hamburger Menu --> */}
      <button
        id="menuButton"
        className="md:hidden"
        onClick={() => setIsHamburgerMenuOpen(true)}
      >
        <svg
          className="w-8 h-8"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </button>

      <div className="flex gap-8">
        {/* Logo */}
        <Link to="/home" className="flex gap-1">
          <img src="/images/logo.jpg" alt="Logo" className="h-12" />
          <label className="logo">Americana & Company</label>
          <US
            title="United States"
            className="w-10 h-10 min-w-10 min-h-10 self-center"
          />
        </Link>

        {/* Navigation Tabs */}
        <nav className="hidden md:flex space-x-3 px-12">
          <TabDropdownList />
        </nav>
      </div>

      {/* Search and Cart Buttons */}
      <div className="hidden md:flex items-center space-x-4">
        <div className="search-bar">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-input"
            placeholder="Search..."
          />
          {searchQuery && (
            <button onClick={clearSearch} className="clear-button">
              &times; {/* This is the "X" symbol */}
            </button>
          )}
        </div>
        <button
          onClick={() => setIsCartOpen(!isCartOpen)}
          className="text-lg hidden md:block"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 3h2l.4 2M7 13h10l1.1-5H5.9L7 13zm0 0l1.1 5H17l1.1-5M7 13v8a1 1 0 001 1h8a1 1 0 001-1v-8"
            ></path>
          </svg>
        </button>
      </div>
    </header>
  );
};

export default Header;

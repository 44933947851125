import { Theme } from "../types/Theme";
import { Product } from "../types/Product";
import { ProductCategory } from "../types/ProductCategory";
import { ProductLine } from "../types/ProductLine";
import { Size } from "../types/Size";
import { Season } from "../types/Season";
import { Color } from "../types/Color";

export const Products: Product[] = [
  {
    id: 1,
    name: "The Logo Sweater",
    description: "Our custom logo on an comfortably fashionable sweater!",
    price: 79.99,
    url: "sweater-logo",
    productCategories: [ProductCategory.Apparel],
    productLine: ProductLine.Sweaters,
    themes: [
      { theme: Theme.New, years: [2024, 2025] },
      { theme: Theme.Season, season: Season.Fall, years: [2024] },
      { theme: Theme.Season, season: Season.Winter, years: [2024] },
      { theme: Theme.Classic },
    ],
    dateOnMarket: new Date("11/12/2024"),
    images: [
      {
        color: Color.Brown,
        imagePaths: ["/images/products/sweater-logo-brown-2.png"],
      },
    ],
    sizes: [
      Size.XS,
      Size.S,
      Size.M,
      Size.L,
      Size.XL,
      Size.XXL,
      Size.XXXL,
      Size.XXXXL,
      Size.XXXXXL,
    ],
    availableColors: [Color.Brown],
  },
  {
    id: 2,
    name: "The Logo Polo",
    description: "Our custom logo on a business casual polo!",
    price: 49.99,
    url: "polo-logo",
    productCategories: [ProductCategory.Apparel],
    productLine: ProductLine.Tshirts,
    themes: [
      { theme: Theme.New, years: [2024, 2025] },
      { theme: Theme.Season, season: Season.Winter, years: [2024] },
      { theme: Theme.Season, season: Season.Spring, years: [2025] },
      { theme: Theme.Season, season: Season.Summer, years: [2025] },
      { theme: Theme.Season, season: Season.Fall, years: [2025] },
      { theme: Theme.Classic },
    ],
    dateOnMarket: new Date("11/12/2024"),
    images: [
      {
        color: Color.Brown,
        imagePaths: [
          "/images/products/polo-logo-brown-1.png",
          "/images/products/polo-logo-brown-2.png",
        ],
      },
    ],
    sizes: [
      Size.XS,
      Size.S,
      Size.M,
      Size.L,
      Size.XL,
      Size.XXL,
      Size.XXXL,
      Size.XXXXL,
      Size.XXXXXL,
    ],
    availableColors: [Color.Brown],
  },
  {
    id: 3,
    name: "Arsenal of Democracy Tank",
    description: "Want to look good at the gym?",
    price: 29.99,
    url: "tank-arsenalofdemocracy",
    productCategories: [ProductCategory.Apparel],
    productLine: ProductLine.Tshirts,
    themes: [
      { theme: Theme.Season, season: Season.Spring, years: [2025] },
      { theme: Theme.Season, season: Season.Summer, years: [2025] },
      { theme: Theme.Classic },
    ],
    dateOnMarket: new Date("11/12/2024"),
    images: [
      {
        color: Color.Army_Green,
        imagePaths: [
          "/images/products/tanktop-arsenal-green-2.png",
          "/images/products/tanktop-arsenal-green-3.png",
        ],
      },
    ],
    sizes: [
      Size.XS,
      Size.S,
      Size.M,
      Size.L,
      Size.XL,
      Size.XXL,
      Size.XXXL,
      Size.XXXXL,
      Size.XXXXXL,
    ],
    availableColors: [Color.Army_Green],
  },
  {
    id: 4,
    name: "Speak Softly T-Shirt",
    description: "...and carry a big stick!",
    price: 29.99,
    url: "tshirt-speaksoftly",
    productCategories: [ProductCategory.Apparel],
    productLine: ProductLine.Tshirts,
    themes: [
      { theme: Theme.Season, season: Season.Spring, years: [2025] },
      { theme: Theme.Season, season: Season.Summer, years: [2025] },
      { theme: Theme.Classic },
    ],
    dateOnMarket: new Date("11/12/2024"),
    images: [
      {
        color: Color.Brown,
        imagePaths: [
          "/images/products/tshirt-speaksoftly-brown-1.png",
          "/images/products/tshirt-speaksoftly-brown-2.png",
          "/images/products/tshirt-speaksoftly-brown-3.png",
        ],
      },
    ],
    sizes: [
      Size.XS,
      Size.S,
      Size.M,
      Size.L,
      Size.XL,
      Size.XXL,
      Size.XXXL,
      Size.XXXXL,
      Size.XXXXXL,
    ],
    availableColors: [Color.Brown],
  },
];

export const OldProducts: Product[] = [
  {
    id: 901,
    name: "The Flag Sweater",
    description: "This sweater will make you look fly!",
    price: 79.99,
    url: "sweater-flag-green",
    productCategories: [ProductCategory.Apparel],
    productLine: ProductLine.Sweaters,
    themes: [
      { theme: Theme.New, years: [2024] },
      { theme: Theme.Season, season: Season.Fall, years: [2024] },
      { theme: Theme.Season, season: Season.Winter, years: [2024] },
      { theme: Theme.Classic },
    ],
    dateOnMarket: new Date("08/28/2024"),
    images: [
      {
        color: Color.Sand,
        imagePaths: ["/images/products/old/sweater-flag-bright.jpg"],
      },
      {
        color: Color.Army_Green,
        imagePaths: ["/images/products/old/sweater-flag-green.jpg"],
      },
    ],
    sizes: [
      Size.XS,
      Size.S,
      Size.M,
      Size.L,
      Size.XL,
      Size.XXL,
      Size.XXXL,
      Size.XXXXL,
      Size.XXXXXL,
    ],
    availableColors: [Color.Army_Green, Color.Sand],
  },
  {
    id: 903,
    name: "Americana Company Tank",
    description: "These jeans will make you look fly!",
    price: 49.99,
    url: "tanktop-americanacompany-green",
    productCategories: [ProductCategory.Apparel],
    productLine: ProductLine.Tshirts,
    themes: [
      { theme: Theme.Season, season: Season.Spring, years: [2025] },
      { theme: Theme.Season, season: Season.Summer, years: [2025] },
    ],
    dateOnMarket: new Date("06/16/2024"),
    images: [
      {
        color: Color.Army_Green,
        imagePaths: ["/images/products/tanktop-americanacompany-green.jpg"],
      },
    ],
    sizes: [
      Size.XS,
      Size.S,
      Size.M,
      Size.L,
      Size.XL,
      Size.XXL,
      Size.XXXL,
      Size.XXXXL,
      Size.XXXXXL,
    ],
    availableColors: [Color.Army_Green],
  },
  {
    id: 904,
    name: "Freedom TShirt",
    description: "This tshirt will make you look fly!",
    price: 49.99,
    url: "tshirt-freedom-bright",
    productCategories: [ProductCategory.Apparel],
    productLine: ProductLine.Tshirts,
    themes: [
      { theme: Theme.Season, season: Season.Spring, years: [2025] },
      { theme: Theme.Season, season: Season.Summer, years: [2025] },
    ],
    dateOnMarket: new Date("06/16/2024"),
    images: [
      {
        color: Color.Sand,
        imagePaths: ["/images/products/tshirt-freedom-bright.jpg"],
      },
    ],
    sizes: [
      Size.XS,
      Size.S,
      Size.M,
      Size.L,
      Size.XL,
      Size.XXL,
      Size.XXXL,
      Size.XXXXL,
      Size.XXXXXL,
    ],
    availableColors: [Color.Sand],
  },
];

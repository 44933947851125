export enum Color {
  // White = "White",
  Gray = "Gray",
  // Blue = "Blue",
  Navy_Blue = "Navy_Blue",
  // Green = "Green",
  Army_Green = "Army_Green",
  // Yellow = "Yellow",
  Sand = "Sand",
  Brown = "Brown",
}
